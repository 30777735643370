import { useEffect, useState } from 'react';
import './App.css';
import SignUp from './page/signUp'


const LoginForm = ({onLogin}) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const creds = {
    username: "wlc",
    password:"wlcdemo"
  }

  const handleLogin = () => {
    if (username === creds.username && password == creds.password) {
      onLogin()
    } else {
      alert("Please Enter Valid Username and password")
    }
  }
  return <>
    <input placeholder='Enter Username' value={username} onChange={(e) => {
      setUsername(e.target.value)
    }} />

<input placeholder='Enter Password' type='password' value={password} onChange={(e) => {
      setPassword(e.target.value)
    }} />

    <button onClick={() => {
      handleLogin()
    }}>Login</button>
  </>

}
function App() {

  const [isLoggedin, setLoggedin] = useState(false)

  // useEffect(() => {

  // ,[]})
  return (
    <>
      {isLoggedin ? <SignUp /> : <LoginForm onLogin={() => {
        setLoggedin(true)
      }} />}

    </>
  );
}

export default App;
